

































import { Vue, Component } from 'vue-property-decorator';

@Component({
  head: {
    title() {
      return {
        inner: '상품 사용 및 환불 규정'
      }
    }
  }
})
export default class UseRefund extends Vue {
  get isApp() {
    if (this.$route.query._from) return true;
    else return false;
  }
}
